import { useEffect, useRef } from 'preact/hooks';
import { Footer, Interlude } from '../landing/util';
import { SimpleHeader } from '../landing/hero';
import { Helmet } from 'react-helmet';
import { ContactForm } from './contact-form';
import { getServiceTestimonials } from '../data/service-testimonials';
import type { WorkItem } from '../landing/work';
import { getServiceContent } from '../data/service-content';
import { BUSINESS_DETAILS } from '../data/business-details';

export function ServicePage({ service }: { service: WorkItem }) {
  const testimonials = getServiceTestimonials(service.title);
  
  // Create structured data for the service
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": `${service.title} - Dr. Drip Plumbing & Heating`,
    "provider": {
      "@type": "LocalBusiness",
      "name": BUSINESS_DETAILS.name,
      "image": "/img/logo.png",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "123 Woodland Ave",
        "addressLocality": "Westwood",
        "addressRegion": "NJ",
        "postalCode": "07675",
        "addressCountry": "US"
      }
    },
    "description": service.description,
    "areaServed": "Bergen County and Rockland County",
    "image": service.imgSrc
  };

  return (
    <div className="min-h-screen bg-sapphire-50 flex flex-col">
      <Helmet>
        <title>{service.title} - Dr. Drip Plumbing & Heating Services</title>
        <meta name="description" content={service.description} />
        <meta name="keywords" content={`${service.title.toLowerCase()}, plumbing services, heating services, Bergen County, Rockland County, Dr. Drip Plumbing`} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <SimpleHeader />
      
      <main className="flex-grow">
        <div className="pt-12 bg-sapphire-100">
          <Interlude
            badgeCopy="Service"
            title={service.title}
            description={service.description}
          />
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Service Image */}
          <div className="mb-12">
            <img
              src={service.imgSrc}
              alt={service.title}
              className="w-full max-w-4xl mx-auto rounded-lg shadow-lg object-cover h-[400px]"
            />
          </div>

          {/* Service Details */}
          <div className="mb-16 max-w-4xl mx-auto bg-white rounded-lg shadow-sm p-8">
            <h2 className="text-3xl font-semibold mb-8 text-sapphire-900">
              About Our {service.title} Services
            </h2>
            <div className="prose prose-lg max-w-none">
              {getServiceContent(service.title)}
            </div>
          </div>

          {/* Testimonials */}
          {testimonials.length > 0 && (
            <div className="mb-16 max-w-4xl mx-auto">
              <h2 className="text-2xl text-sapphire-900 font-semibold mb-8 text-center">
                What Our Customers Say About Our {service.title} Service
              </h2>
              <div className="grid gap-8 md:grid-cols-2">
                {testimonials.map((testimonial, index) => (
                  <div 
                    key={index}
                    className="bg-white p-8 rounded-lg shadow-sm"
                  >
                    <blockquote className="text-sapphire-800 text-lg">
                      "{testimonial.quote}"
                    </blockquote>
                    <div className="mt-6 flex items-center">
                      {testimonial.avatar && (
                        <img 
                          src={testimonial.avatar}
                          alt={testimonial.alt || `${testimonial.author}'s profile`}
                          className="w-12 h-12 rounded-full mr-4"
                        />
                      )}
                      <div>
                        <div className="font-semibold text-sapphire-900">
                          {testimonial.author}
                        </div>
                        {testimonial.source && (
                          <img 
                            src={testimonial.source}
                            alt="Review source"
                            className="h-5 mt-1"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Contact Form Section */}
          <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="bg-sapphire-900 text-white py-8 px-6 text-center">
              <h2 className="text-2xl font-semibold mb-2">
                Need {service.title} Services?
              </h2>
              <p className="text-sapphire-100">
                Contact us today for a consultation or emergency service
              </p>
            </div>
              <ContactForm />
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
} 