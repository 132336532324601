import { COMPANY_EMAIL } from "../landing/util"
import { BUSINESS_DETAILS } from "../data/business-details"

export function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
        <p className="mb-4">We collect information that you provide directly to us, including:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Name and contact information</li>
          <li>Service address and billing information</li>
          <li>Communications you send to us</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
        <p className="mb-4">We use the information we collect to:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Provide plumbing services</li>
          <li>Process your payments</li>
          <li>Communicate with you about services</li>
          <li>Send you marketing communications (with your consent)</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Information Sharing</h2>
        <p>We do not sell or rent your personal information to third parties. We may share your information with service providers who assist in our operations.</p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p>If you have questions about our privacy practices, please contact us at:</p>
        <p className="mt-2">
          Dr. Drip Plumbing<br />
          {BUSINESS_DETAILS.address}<br />
          <a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a>
        </p>
      </section>
    </div>
  )
} 