import { Interlude } from './util';
import { useEffect, useRef } from 'preact/hooks';
import { BUSINESS_DETAILS } from '../data/business-details';

// We cover Bergen, Rockland, Passaic, Hudson, and Sussex, Essex, and Morris counties. 
// If possible we place emphasis on the towns surrounding immediate area - Westwood, 
// River Vale, Hillsdale, Montvale, saddle river, upper saddle river, Mahwah, Ramsey, ho ho kus,
//  old Tappan, Tappan, new city, Norwood, Northvale, Harrington park, Cresskill, Tenafly, alpine 
// Franklin lakes, Oakland, Wyckoff
// Ridgewood, suffern, Sloatsburg 
// Woodcliff lake 
// Closter 
// Haworth, Bergenfield, Dumont 
// Emerson, Oradell, river edge, Paramus 

export function ServiceArea() {
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapRef.current) return;

    // Dynamically import the Loader
    import('@googlemaps/js-api-loader').then(({ Loader }) => {
      const loader = new Loader({
        apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
        version: "weekly",
      });

      loader.load().then(() => {
        const map = new google.maps.Map(mapRef.current!, {
          zoom: 15,
          center: BUSINESS_DETAILS.location,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        });

        // Create an info window
        const infoWindow = new google.maps.InfoWindow();

        // Create marker for the business
        const marker = new google.maps.Marker({
          map,
          position: BUSINESS_DETAILS.location,
          title: BUSINESS_DETAILS.name,
          animation: google.maps.Animation.DROP
        });

        // Add click listener to marker
        marker.addListener("click", () => {
          const content = `
            <div>
              <h3 style="font-weight: bold; margin-bottom: 4px;">${BUSINESS_DETAILS.name}</h3>
              <p style="margin: 0;">${BUSINESS_DETAILS.address}</p>
              <p style="margin: 4px 0;">Rating: ⭐ ⭐ ⭐ ⭐ ⭐</p>
              <p style="margin: 4px 0;">Reviews: 128+</p>
            </div>
          `;
          
          infoWindow.setContent(content);
          infoWindow.open(map, marker);
        });

        // Trigger click to show info window initially
        google.maps.event.trigger(marker, 'click');
      });
    });
  }, []);

  return (
    <div className="py-24 bg-white">
      <Interlude
        className="bg-white"
        badgeCopy="Location"
        title="Service Area"
        description="We proudly service Bergen, Rockland, Passaic, Hudson, and Sussex, Essex, and Morris counties."
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div 
          ref={mapRef} 
          className="w-full h-[500px] rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
} 